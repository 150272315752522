@mixin vp-1919 {
  @media (max-width: (1919px)) {
    @content;
  }
}

@mixin vp-1439 {
  @media (max-width: (1439px)) {
    @content;
  }
}

@mixin vp-1368 {
  @media (max-width: (1368px)) {
    @content;
  }
}

@mixin vp-1279 {
  @media (max-width: (1279px)) {
    @content;
  }
}

@mixin vp-1024 {
  @media (max-width: (1024px)) {
    @content;
  }
}

@mixin vp-767 {
  @media (max-width: (767px)) {
    @content;
  }
}

@mixin vp-435 {
  @media (max-width: (440px)) {
    @content;
  }
}

@mixin showMoreButton {
  justify-self: center;
  margin: 0 auto;

  @include vp-435 {
    width: 100%;
    padding: 15px 0;
    max-height: 52px;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
  }
}

@mixin title-36 {
  font-size: 36px;
  font-weight: 800;
  line-height: 38px;
  text-align: center;

  @include vp-435 {
    font-size: 24px;
    font-weight: 800;
    line-height: 28px;
  }
}

@mixin text-24 {
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;
}

@mixin text-16 {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}

@mixin text-14 {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@mixin text($size, $weight, $height) {
  font-size: $size;
  font-weight: $weight;
  line-height: $height;
}
