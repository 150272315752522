@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 72px;

  @include vp-435 {
    margin-bottom: 42px;
  }
}

.title {
  @include text(24px, 800, 38px);
  text-align: left;
}
