@import 'src/styles/variables';
@import 'src/styles/mixins';

.slider {
  display: block;
  width: 100%;
  position: relative;
  margin-bottom: 64px;
  height: 670px;

  @include vp-435 {
    display: none;
  }
}

.sliderMobile {
  display: none;

  @include vp-435 {
    height: auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 0 42px 0;
  }
}

.space {
  height: 420px;
  display: none;

  @include vp-435 {
    display: block;
  }
}

.next {
  position: absolute;
  top: 300px;
  right: 16px;
  z-index: 1;
}

.prev {
  position: absolute;
  top: 300px;
  left: 16px;
  z-index: 1;
  transform: rotate(180deg);
}

.bullet {
  display: block;
  width: 6px;
  height: 6px;
  max-width: 6px;
  max-height: 6px;
  background-color: $gray;
  border-radius: 50px;
  cursor: pointer;

  &Active {
    background-color: $primary;
  }
}
