@import 'src/styles/mixins';
@import 'src/styles/variables';
@import 'src/styles/mixins';

.tag {
  padding: 4px 16px;
  color: $gray;
  background-color: $white;
  width: fit-content;
  letter-spacing: 2px;
  border-radius: 24px;
  @include text(10px, 800, 16px);
  white-space: nowrap;
  text-transform: uppercase;
}
